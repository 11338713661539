/* eslint react/prop-types: 0 */
import React from 'react';
import useGetMerchantsAndVendors from '../../Merchants/useGetMerchantsAndVendors';
import SelectWithDefault from './SelectWithDefault';

const formatOptionLabel = ({ label, type }) => (
  <span
    style={{
      fontSize: '0.8rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      overflow: 'hidden',
      lineHeight: '100%',
      gap: 6,
    }}
  >
    <span
      className="badge"
      style={{
        backgroundColor: `var(--bs-${type === 'merchant' ? 'primary' : 'blue'})`,
      }}
    >
      {type.toUpperCase()}
    </span>{' '}
    {label}
  </span>
);

function SelectMerchantOrVendor({ isInvalid, name, setFieldValue, value }) {
  const options = useGetMerchantsAndVendors();
  const handleChange = (e) => {
    const nextValue = e?.value ? e.value : '';
    setFieldValue(`${name}`, `${nextValue}`);
    if (nextValue !== value[name]) {
      if (nextValue === '') {
        setFieldValue('merchant_id', '');
        setFieldValue('vendor_id', '');
      } else if (nextValue.slice(0, 1) === 'm') {
        setFieldValue('merchant_id', nextValue.slice(1));
        setFieldValue('vendor_id', '');
      } else {
        setFieldValue('vendor_id', nextValue.slice(1));
        setFieldValue('merchant_id', '');
      }
      setFieldValue('merchant_vendor_selector', nextValue || '');
    }
  };

  const isLoading = !options;

  return (
    <SelectWithDefault
      isLoading={isLoading}
      name={name}
      options={options}
      value={value}
      isInvalid={isInvalid}
      handleChange={async (e) => handleChange(e)}
      setFieldValue={setFieldValue}
      formatOptionLabel={formatOptionLabel}
    />
  );
}

export default SelectMerchantOrVendor;
