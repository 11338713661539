import { useEffect } from 'react';
import { useFetcher } from 'react-router-dom';
import useIsAuthorized from '../../routes/protectedPages/useIsAuthorized';

export default function useGetMerchantsAndVendors(modifiers = null) {
  const fetcher = useFetcher();
  useEffect(() => {
    if (fetcher.state === 'idle' && !fetcher.data) {
      fetcher.load('/expenses/merchants-and-vendors');
    }
  }, [fetcher]);

  const showVendors = useIsAuthorized({
    tiersAllowed: ['tier_2', 'tier_3'],
  });

  let merchantsAndVendorsOptions;

  if (showVendors) {
    merchantsAndVendorsOptions = fetcher?.data && [
      ...fetcher.data.vendors.map((item) => ({
        value: `v${item.id}`,
        label: `${item.label}`,
        type: 'vendor',
        isDisabled: modifiers ? modifiers(item) : false,
      })),
      ...fetcher.data.merchants.map((item) => ({
        value: `m${item.id}`,
        label: `${item.label}`,
        type: 'merchant',
        isDisabled: modifiers ? modifiers(item) : false,
      })),
    ];
  } else {
    merchantsAndVendorsOptions = fetcher?.data && [
      ...fetcher.data.merchants.map((item) => ({
        value: `m${item.id}`,
        label: `${item.label}`,
        type: 'merchant',
        isDisabled: modifiers ? modifiers(item) : false,
      })),
    ];
  }
  return merchantsAndVendorsOptions;
}
