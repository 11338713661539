import React from 'react';
import propTypes from 'prop-types';
import IconWrapper from '../../Customers/CustomersCsv/IconWrapper';
import { Checkmark } from '../../UI/Icons/Icons';
import planData from './planData';
import LBTooltip from '../../UI/LBTooltip/LBTooltip';

function PlanToggle({ tier }) {
  const [showFeatures, setShowFeatures] = React.useState(false);

  return (
    <ul className="lb-subscription__feature-list">
      {tier !== 'tier_1' && (
        <>
          <li>
            <IconWrapper strokeWidth={4} stroke="var(--bs-primary)">
              <Checkmark />
            </IconWrapper>
            <LBTooltip
              tooltipText={`${showFeatures ? 'Hide' : 'Show'} details`}
            >
              <button
                type="button"
                onClick={() => setShowFeatures(!showFeatures)}
              >
                Everything in
                <span>{tier === 'tier_2' ? 'STARTER' : 'BOOST'}</span>+
              </button>
            </LBTooltip>
          </li>
          {showFeatures &&
            planData
              .find((plan) => plan.id === tier)
              .hoverList.map((feature) => (
                <li key={feature.toLowerCase().replaceAll(' ', '_')}>
                  <IconWrapper strokeWidth={4} stroke="var(--bs-primary)">
                    <Checkmark />
                  </IconWrapper>
                  {feature}
                </li>
              ))}
        </>
      )}

      {planData
        .find((item) => item.id === tier)
        .featureList.map((feature) => (
          <li key={feature.toLowerCase().replaceAll(' ', '_')}>
            <IconWrapper strokeWidth={4} stroke="var(--bs-primary)">
              <Checkmark />
            </IconWrapper>
            {feature}
          </li>
        ))}
    </ul>
  );
}

PlanToggle.propTypes = {
  tier: propTypes.oneOf(['tier_1', 'tier_2', 'tier_3']).isRequired,
};

export default PlanToggle;
