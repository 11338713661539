/* eslint-disable camelcase */
import React from 'react';
import { useFetcher } from 'react-router-dom';

interface BackendData {
  message: string;
  alert: string;
  data?: {
    account_id: string;
    account_completed: boolean;
    business_profile: {
      url?: string | null;
    };
  };
}

function useStripeConnectionStatus() {
  const fetcher = useFetcher<BackendData>();

  React.useEffect(() => {
    if (fetcher.state === 'idle' && !fetcher.data) {
      fetcher.load('/settings/stripe-connection-status');
    }
  }, []);

  const { data: fetcherData, state } = fetcher;
  const noAccount = fetcherData?.message === 'no_account';
  const accountCompleted = fetcherData?.data?.account_completed && !noAccount;
  const hasBusinessUrl = fetcherData?.data?.business_profile?.url;
  const canMakePayments = Boolean(accountCompleted && Boolean(hasBusinessUrl));

  return { state, accountCompleted, canMakePayments };
}

export default useStripeConnectionStatus;
