import React from 'react';
import {
  Link,
  Navigate,
  Outlet,
  useLoaderData,
  useRouteLoaderData,
} from 'react-router-dom';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import { differenceInDays, parseISO, compareAsc } from 'date-fns';
import planData from './planData.ts';
import UsageMeter from './UsageMeter';
import InvalidSubscriptionPage from './InvalidSubscriptionPage';
import PlanToggle from './PlanToggle';

import './ManageSubscription.scss';
import formatCurrency from '../../Dashboard/Widgets/utils/formatCurrency';
import FetcherModal from './FetcherModal.tsx';

function ManageSubscription() {
  const {
    user: {
      subscription: {
        tier,
        current_invoices: currentInvoices,
        max_invoices: maxInvoices,
      },
    },
  } = useRouteLoaderData('root');
  const {
    data: {
      next_date: nextDateISO,
      status,
      billing_cycle: billingCycle,
      trial_end: trialEnd,
    },
  } = useLoaderData() || {};

  const sanitizedStatus = status.toLowerCase();

  const expirationDate = nextDateISO
    ? parseISO(nextDateISO).toLocaleDateString('en-US', { dateStyle: 'full' })
    : 'Error retrieving date';

  const plan = planData.find((p) => p.id === tier);

  const maxTier = billingCycle === 'yearly' && tier === 'tier_3';
  const canPurchase = sanitizedStatus !== 'cancelled' && !maxTier;
  const trialEndDate = parseISO(trialEnd);

  const isTrialAcc = compareAsc(new Date(), trialEndDate) < 0;

  if (tier === 'expired') {
    return <Navigate to="./plan-list" />;
  }

  // ! Pending was being used as an invalid state before, we should
  // ! review the BE logic and ensure that "pending" is only applied
  // ! for payment delays, and display a better UI
  if (tier === 'unsubscribed' || tier === 'pending') {
    return <InvalidSubscriptionPage />;
  }

  return (
    <div className="container-fluid p-4 h-100 w-100 rounded lb-subscription__wrapper">
      <Row className="h-100 gx-5 lb-subscription__main-container">
        <Col xs={12} lg={6} xl>
          <article className="lb-subscription__plan-container">
            <div>
              <h4 className="lb-subscription__plan-container__pre">
                Your plan
              </h4>
              <h2 className="lb-subscription__plan-container__plan-name d-flex align-items-center gap-3">
                {plan.name}{' '}
                {isTrialAcc && (
                  <span
                    style={{ fontSize: '0.8rem' }}
                    className="badge text-bg-warning text-white"
                  >
                    TRIAL
                  </span>
                )}
              </h2>
              <p className="lb-subscription__plan-container__description">
                {plan.description}
              </p>
            </div>
            <div className="lb-subscription__plan-container__action-group">
              <Alert
                variant="secondary"
                style={{ fontSize: '0.8rem' }}
                className="mt-4"
              >
                Do you need a specific feature for the system to better suit
                your business? Contact us and send your request to{' '}
                <Alert.Link href="mailto:support@leadsboxinvoice.com">
                  support@leadsboxinvoice.com
                </Alert.Link>
                .
              </Alert>
              {canPurchase && (
                <Button
                  as={Link}
                  to="/settings/subscription/plan-list"
                  variant="primary"
                >
                  {sanitizedStatus !== 'active' ? 'Get a' : 'Manage'}{' '}
                  Subscription
                </Button>
              )}
              {sanitizedStatus === 'cancelled' && (
                <FetcherModal
                  actionUrl="/settings/subscription/resume"
                  triggerLabel="Re-enable subscription"
                >
                  This will re-enable your subscription, after the expiration
                  date, <strong>{expirationDate}</strong> you will be charged{' '}
                  <strong>{formatCurrency(plan.price[billingCycle])}</strong>
                </FetcherModal>
              )}
              {sanitizedStatus !== 'cancelled' && (
                <FetcherModal
                  actionUrl="/settings/subscription/cancel"
                  triggerLabel="Cancel subscription"
                  confirmVariant="danger"
                  variant="danger"
                >
                  Are you sure you want to cancel your subscription? If you need
                  help or are facing an issue don&apos;t hesitate to{' '}
                  <Link className="fw-semibold" to="/support">
                    contact support
                  </Link>
                  . If you still want to cancel, please confirm by pressing the
                  button below.
                </FetcherModal>
              )}
            </div>
          </article>
        </Col>
        <Col xs={12} lg={6} xl>
          <article>
            <h4 className="lb-subscription__heading">Included Features: </h4>

            {tier === 'tier_1' && <PlanToggle tier="tier_1" />}
            {tier === 'tier_2' && <PlanToggle tier="tier_2" />}
            {tier === 'tier_3' && <PlanToggle tier="tier_3" />}
          </article>
        </Col>
        <Col xs={12} lg={6} xl>
          <article>
            <h4 className="lb-subscription__heading">Billing: </h4>
            {sanitizedStatus === 'cancelled' ? (
              <Alert variant="danger" style={{ fontSize: '0.85rem' }}>
                Your subscription has been <strong>cancelled</strong> it will
                end on <strong>{expirationDate}</strong> and you won&apos;t be
                able to access premium features after that date. You can get a
                new subscription at any time.
              </Alert>
            ) : (
              <Alert
                variant={isTrialAcc ? 'warning' : 'info'}
                style={{ fontSize: '0.85rem' }}
              >
                {isTrialAcc ? (
                  <span>
                    Your trial will end on{' '}
                    <strong>
                      {trialEndDate.toLocaleDateString('en-US', {
                        dateStyle: 'full',
                      })}
                    </strong>
                    . At the end of the trial period you will be charged{' '}
                    <strong>{formatCurrency(plan.price[billingCycle])}</strong>
                  </span>
                ) : (
                  <span>
                    Your {billingCycle} subscription will renew on{' '}
                    <strong>{expirationDate}</strong> and you will be charged{' '}
                    {formatCurrency(plan.price[billingCycle])}.
                  </span>
                )}
              </Alert>
            )}
            {sanitizedStatus === 'active' && (
              <p className="lb-subscription__note">
                {' '}
                If you wish to cancel your subscription, it will remain active
                until the end of your subscription cycle.
              </p>
            )}
            {sanitizedStatus === 'active' && (
              <p className="lb-subscription__note mt-2">
                To downgrade your subscription, you need cancel it first.
              </p>
            )}
            <p className="lb-subscription__note mt-2">
              We do not offer refunds for unused time on the system.
            </p>
          </article>
        </Col>

        {tier === 'tier_1' && (
          <Col xs={12} lg={6} xl>
            <article>
              <header className="d-flex flex-column flex-sm-row align-items-baseline justify-content-between lb-subscription__heading">
                <h4
                  className="d-inline-block"
                  style={{ fontStyle: 'inherit', fontSize: 'inherit' }}
                >
                  Usage:{' '}
                </h4>
                <span
                  className="mt-2 mt-sm-0"
                  style={{
                    fontSize: '0.8rem',
                    color: 'var(--bs-gray-600)',
                  }}
                >
                  <em>
                    Resets in {differenceInDays(expirationDate, new Date())}{' '}
                    days
                  </em>
                </span>
              </header>
              <p>
                Your current plan has a monthly invoice limit. If you want to
                create more invoices, you can{' '}
                <Link to="/settings/subscription/plan-list">
                  upgrade your plan
                </Link>
                .
              </p>

              <UsageMeter
                maxInvoices={maxInvoices}
                currentInvoices={currentInvoices}
              />
            </article>
          </Col>
        )}
      </Row>
      <Outlet />
    </div>
  );
}

export default ManageSubscription;
