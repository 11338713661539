/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint react/prop-types: 0 */
/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import {
  useSubmit,
  useParams,
  useLocation,
  useFetcher,
  useOutletContext,
} from 'react-router-dom';
import CreateForm from '../Forms/CreateForm/CreateForm';
import FormWrapper from '../Forms/builders/FormWrapper';
import useFormikTemplate from '../Forms/hooks/useFormikTemplate';
import useFetcherOnce from '../Invoices/useFetcherOnce';
import useStripeConnectionStatus from '../../hooks/use-stripe-connection-status.ts';
import StripeConnectAlert from './StripeConnectAlert';

const PAYMENT_OPTIONS = [
  { value: 1, label: 'Cash' },
  { value: 2, label: 'Credit Card' },
  { value: 3, label: 'Check' },
  { value: 4, label: 'Money Transfer' },
  { value: 5, label: 'Other' },
];

const getPaymentOptions = (enableCreditCard) => {
  if (!enableCreditCard) {
    return PAYMENT_OPTIONS.filter((opt) => opt.value !== 2);
  }
  return PAYMENT_OPTIONS;
};

export default function CreatePayment() {
  const data = useOutletContext();
  const [checkNumber, setCheckNumber] = React.useState(false);
  const { paymentId } = useParams();
  const nextNumber = useFetcherOnce('/payments/nextPaymentNumber', !paymentId);
  const documentNumber = nextNumber?.data?.payment_number || '';
  const location = useLocation();
  const message = useFetcherOnce('/settings/messages/receipts');

  const payment = data?.payment || {};
  const invoice = data?.invoice || {};

  // We use this fetcher to set the payment amount to the max balance
  // of the selected invoice. It's declared here to disable the field while
  // the fetcher is loading
  const invoicesFetcher = useFetcher();

  const {
    state: stripeFetcherState,
    canMakePayments,
    accountCompleted,
  } = useStripeConnectionStatus();
  const enablePaymentSelect = stripeFetcherState === 'idle';

  const fields = [
    {
      formGroup: 'Payment Information',
      groupChildren: [
        {
          name: 'invoice_id',
          label: 'Invoice',
          formType: 'invoiceSelect',
          required: true,
          initialValue: invoice.id || location?.state?.invoiceId || '',
          type: Yup.string().required('Required'),
        },
        {
          name: 'payment_date',
          label: 'Payment Date',
          formType: 'date',
          required: true,
          initialValue:
            payment?.date || new Date().toISOString().substring(0, 10),
          type: Yup.date().required('Required'),
        },
        {
          name: 'payment_amount',
          label: 'Payment Amount',
          formType: 'number',
          required: true,
          initialValue: payment?.amount || '',
          type: Yup.number()
            .positive('Amount must be more than zero')
            .required('Required'),

          disabled: invoicesFetcher.state === 'loading',
          // plaintext: invoicesFetcher.state === 'loading',
        },
        {
          name: 'payment_method_id',
          label: 'Payment Method',
          formType: 'select',
          required: true,
          isLoading: !enablePaymentSelect,
          disabled: !enablePaymentSelect,
          initialValue: payment?.method_id || '',
          type: Yup.string().required('Required'),
          options: getPaymentOptions(canMakePayments),
        },
        {
          name: 'payment_number',
          label: 'Payment number',
          initialValue: payment?.number || documentNumber,
          type: Yup.string().required('Please add the payment number'),
          required: true,
          plaintext: true,
          disabled: true,
          readOnly: true,
        },
        {
          name: 'check_number',
          label: 'Check Number',
          formType: 'text',
          required: true,
          initialValue: payment?.check || '',
          type: Yup.string().when('payment_method_id', {
            is: 3 || '3',
            then: Yup.string().required('Required'),
          }),
          className: checkNumber ? '' : 'invisible',
        },
        {
          name: 'statement_notes',
          label: 'Statement notes',
          formType: 'textarea',
          initialValue:
            payment?.statement_notes ||
            (!paymentId && message?.data?.data?.statement_notes) ||
            '',
          type: Yup.string(),
          maxLength: 800,
        },
        {
          name: 'private_notes',
          label: 'Private notes',
          formType: 'textarea',
          initialValue: payment?.private_notes || '',
          type: Yup.string(),
          maxLength: 1000,
        },
      ],
    },
  ];

  const submit = useSubmit();
  const submitAction = (values) => {
    submit(values, { method: 'post' });
  };

  // Initial values
  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
  });

  // conditionally render chack number field
  useEffect(() => {
    if (
      formik.values.payment_method_id === 3 ||
      formik.values.payment_method_id === '3'
    ) {
      setCheckNumber(true);
    } else {
      setCheckNumber(false);
      if (formik.values.check_number) formik.setFieldValue('check_number', '');
    }
  }, [formik.values.payment_method_id]);

  // set payment amount when invoice_id changes
  // fetch the invoice data
  useEffect(() => {
    if (formik.values.invoice_id) {
      invoicesFetcher.load(`/invoices/${formik.values.invoice_id}`);
    }
  }, [formik.values.invoice_id]);

  // when the fetcher changes and the invoice_id changes update the field
  useEffect(() => {
    // we don't want no overrite the value if we have a payment id
    if (paymentId) return;

    // if we are creating a new payment, we want this functionality
    if (
      !formik.values.invoice_id ||
      !invoicesFetcher?.data?.data.invoice.amount.balance
    )
      return;

    formik.setFieldValue(
      'payment_amount',
      invoicesFetcher?.data?.data.invoice.amount.balance
    );
  }, [invoicesFetcher, formik.values.invoice_id]);

  return (
    <>
      {stripeFetcherState === 'idle' && !canMakePayments && (
        <StripeConnectAlert accountCompleted={accountCompleted} />
      )}

      <FormWrapper
        id="paymentForm"
        handleSubmit={formik.handleSubmit}
        isSubmitting={formik.isSubmitting}
        dirty={formik.dirty}
        isValid={formik.isValid}
        label={
          `${formik.values.payment_method_id}` === '2'
            ? 'Pay with stripe'
            : 'Save'
        }
        variant={
          `${formik.values.payment_method_id}` === '2' ? 'success' : 'primary'
        }
      >
        <CreateForm structure={fields} {...formik} />
      </FormWrapper>
    </>
  );
}
