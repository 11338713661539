/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useFetcher } from 'react-router-dom';

interface FetcherModalProps {
  actionUrl: string;
  triggerLabel: string;
  placeholderLength?: string;
  confirmVariant?: string;
  cancelLabel?: string;
  confirmLabel?: string;
  children: ReactNode;
}

function FetcherModal({
  actionUrl,
  triggerLabel,
  confirmVariant = 'primary',
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
  placeholderLength = '9ch',
  children,
  ...buttonProps
}: FetcherModalProps) {
  const [show, setShow] = React.useState(false);
  const fetcher = useFetcher();

  // ! This looks redundant, but maybe in the future we can better implement
  // ! a transition for closing on redirection, and we may need to abstract the closing/opening
  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <Button {...buttonProps} onClick={handleOpen}>
        {triggerLabel}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{triggerLabel}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {cancelLabel}
          </Button>

          <fetcher.Form method="POST" action={actionUrl}>
            <Button
              type="submit"
              variant={confirmVariant}
              style={{ minWidth: placeholderLength }}
            >
              {fetcher.state !== 'idle' ? <Spinner size="sm" /> : confirmLabel}
            </Button>
          </fetcher.Form>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FetcherModal;
