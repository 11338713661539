/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { string } from 'yup';
import { useFetcher } from 'react-router-dom';
import CreateForm from '../Forms/CreateForm';
import useFormikTemplate from '../Forms/hooks/useFormikTemplate';

// eslint-disable-next-line react/prop-types
function BusinessUrlForm({ redirectTo = '/payments/new' }) {
  const [show, setShow] = React.useState(false);

  const fetcher = useFetcher();

  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  const fields = [
    {
      name: 'business_url',
      label: 'Business URL',
      initialValue: '',
      type: string()
        .url('Please enter a valid url')
        .transform((currentValue) => {
          const doesNotStartWithHttp =
            currentValue &&
            !(
              currentValue.startsWith('http://') ||
              currentValue.startsWith('https://')
            );

          if (doesNotStartWithHttp) {
            return `http://${currentValue}`;
          }
          return currentValue;
        })
        .required(),
      required: true,
    },
  ];

  function submitAction(values) {
    return fetcher.submit(
      { ...values, redirectTo },
      {
        method: 'POST',
        action: '/payments/update-business-url',
      }
    );
  }

  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
  });

  const { isValid, isDirty } = formik;

  return (
    <>
      <Button variant="warning" size="sm" onClick={handleOpen}>
        Add business URL
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update business URL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fetcher.Form
            id="businessUrlForm"
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <CreateForm structure={fields} {...formik} />
          </fetcher.Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>

          <Button
            type="submit"
            form="businessUrlForm"
            variant="primary"
            disabled={fetcher.state !== 'idle' || (!isValid && !!isDirty)}
            style={{ minWidth: '9ch' }}
          >
            {fetcher.state !== 'idle' ? <Spinner size="sm" /> : 'Update'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BusinessUrlForm;
