/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-restricted-globals */

import React, { useState } from 'react';
import { compareAsc, parseISO } from 'date-fns';
import { Alert, Button, Modal, Spinner } from 'react-bootstrap';
import {
  useFetcher,
  useLoaderData,
  useNavigate,
  useNavigation,
  useRouteLoaderData,
} from 'react-router-dom';
import PLAN_DATA from './planData';
import type { Plan } from './planData';
import PlanToggle from './PlanToggle';

import './ManageSubscription.scss';
import IconButton from '../../UI/IconButton/IconButton';
import { Cross } from '../../UI/Icons/Icons';
import LBTooltip from '../../UI/LBTooltip/LBTooltip';
import PricingToggle, { PricingOptions } from './PricingToggle';

interface PlanItemProps {
  plan: Plan;
  priceId: string | false;
  pricing: PricingOptions;
}

function PlanItem({ plan, priceId, pricing }: PlanItemProps) {
  const { name, description, id, isFeatured, price } = plan;
  const {
    data: { status, trial_end: trialEnd },
  } = useRouteLoaderData('sub-details') as SubscriptionLoaderData;
  const sanitizedStatus = status.toLowerCase();
  const trialEndDate = parseISO(trialEnd);
  const isTrialAcc = compareAsc(new Date(), trialEndDate) < 0;

  const fetcher = useFetcher();

  const [show, setShow] = useState<boolean>(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  // ! I've replaced the form, don't really like using onClick for this,
  // ! but I think it kind of makes sense here
  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('subscription_type', pricing);
    if (priceId) formData.append('price_id', priceId);

    fetcher.submit(formData, {
      method: 'POST',
      action: '/settings/subscription/plan-list',
    });
  };

  return (
    <div
      className={`lb-subscription__plan-list__card ${isFeatured ? 'lb-subscription__plan-list__card--featured' : ''}`}
    >
      <h5 className="text-primary fw-semibold">{name}</h5>
      <p>{description}</p>

      {priceId ? (
        <div className="mt-3 d-flex flex-column">
          <div className="mb-3 text-muted">
            {pricing === 'yearly' && (
              <span className="small fw-bold text-success">
                Save ${(price.monthly * 12 - price.yearly).toFixed(2)}
              </span>
            )}
            <div className="d-flex gap-1 align-items-center">
              ${' '}
              <span className="fs-2 fw-bold text-primary">
                {price[pricing]}
              </span>{' '}
              <span>per {pricing === 'monthly' ? 'month' : 'year'}</span>
            </div>
          </div>
          <Button onClick={handleShow} className="mb-4">
            {sanitizedStatus === 'active' ? 'Upgrade' : 'Subscribe'}
          </Button>
          <Modal
            show={show}
            onHide={handleClose}
            backdropClassName="lb-subscription__plan-list__backdrop-settings lb-subscription__plan-list__stacked-backdrop"
          >
            <Modal.Header closeButton>
              <Modal.Title>Upgrade subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isTrialAcc && (
                <Alert variant="danger">
                  You are currently on a trial account, if you upgrade now{' '}
                  <strong>the trial will end</strong> and the paid subscription
                  time will begin.
                </Alert>
              )}
              Your subscription will be upgraded to {plan.name}, and you will be
              charged <strong>${plan.price[pricing]}</strong> {pricing} from now
              on.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>

              <Button
                type="button"
                variant="primary"
                style={{ minWidth: '9ch' }}
                onClick={handleSubmit}
              >
                {fetcher.state !== 'idle' ? <Spinner size="sm" /> : 'Confirm'}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ) : (
        <LBTooltip
          tooltipText="To downgrade, you need to cancel the subscription first"
          style={{ zIndex: 1055 }}
        >
          <span
            style={{
              pointerEvents: 'all',
              cursor: 'not-allowed',
              marginBottom: '1.2rem',
            }}
          >
            <Button disabled style={{ width: '100%' }}>
              Not available
            </Button>
          </span>
        </LBTooltip>
      )}

      <h4 className="lb-subscription__heading">Included Features: </h4>

      {id === 'tier_1' && <PlanToggle tier="tier_1" />}
      {id === 'tier_2' && <PlanToggle tier="tier_2" />}
      {id === 'tier_3' && <PlanToggle tier="tier_3" />}
    </div>
  );
}

type PlanBEItem = {
  id: string;
  name: string;
  description: string;
  price: {
    monthly: number;
    yearly: number;
  };
  isFeatured: boolean;
  featureList: Array<string>;
  hoverList?: Array<string>;
  maxInvoices: number;
  price_id: {
    monthly: string;
    yearly: string;
  };
};

interface LoaderData {
  message: string;
  subscription_plans?: Array<PlanBEItem>;
}

interface RootLoaderData {
  user: {
    subscription: {
      tier: string;
    };
  };
}

interface SubscriptionLoaderData {
  data: {
    status: 'Active' | 'Cancelled' | 'Expired' | 'Pending';
    billing_cycle: PricingOptions;
    trial_end: string;
  };
}

// ! Need to update to handle yearly subs
const getPriceId = (
  tier: string,
  plans: PlanBEItem[],
  pricing: PricingOptions
) => {
  const result = plans.find((plan) => plan.id === tier)?.price_id[pricing];
  if (!result) return false;
  return result;
};

function PlanList() {
  const navigate = useNavigate();
  const { state } = useNavigation();
  const isLoading = state !== 'idle';
  const [show, setShow] = useState(true);
  const {
    user: {
      subscription: { tier },
    },
  } = useRouteLoaderData('root') as RootLoaderData;
  const {
    data: { status, billing_cycle: billingCycle },
  } = useRouteLoaderData('sub-details') as SubscriptionLoaderData;
  const [pricing, setPrincing] = useState(billingCycle || 'monthly');
  const sanitizedStatus = status.toLowerCase();

  const togglePricing = (opt: PricingOptions) => {
    setPrincing(opt);
  };

  const handleClose = () => {
    setShow(false);
    setTimeout(() => navigate('/settings/subscription'), 300);
  };

  const { subscription_plans: plans } = useLoaderData() as LoaderData;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="lb-subscription__plan-list__modal-settings"
      backdropClassName="lb-subscription__plan-list__backdrop-settings"
      centered
    >
      <span
        className="position-fixed lb-subscription__plan-list__close-btn"
        style={{
          zIndex: 1051,
          cursor: isLoading ? 'not-allowed' : 'pointer',
          pointerEvents: 'all',
          display: !show ? 'none' : 'revert',
        }}
      >
        <IconButton onClick={handleClose} disabled={isLoading}>
          <Cross />
        </IconButton>
      </span>
      <Modal.Body className="lb-subscription__plan-list__wrapper d-flex flex-column align-items-stretch justify-content-center">
        {tier === 'tier_3' &&
        sanitizedStatus === 'active' &&
        billingCycle === 'yearly' ? (
          <Alert variant="info">
            You have the best subscription available, to downgrade first cancel
            your subscription.
          </Alert>
        ) : (
          <PricingToggle pricing={pricing} togglePricing={togglePricing} />
        )}

        <div className="d-flex py-lg-5 flex-column flex-lg-row gap-3 justify-content-center align-items-stretch">
          {plans &&
            PLAN_DATA.map((plan) => (
              <PlanItem
                key={plan.id}
                plan={plan}
                priceId={getPriceId(plan.id, plans, pricing)}
                pricing={pricing}
              />
            ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PlanList;
