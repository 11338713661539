import fetchAPI from '../fetchUtils';

const paymentsAPI = {
  getAll: async (customerId) =>
    fetchAPI.getWithAuth(
      customerId ? `/get-payments?customerId=${customerId}` : '/get-payments'
    ),
  create: async (body) => fetchAPI.postWithAuth('/create-payment', body),
  update: async (body) => fetchAPI.postWithAuth('/update-payment', body),
  get: async (id) => fetchAPI.getWithAuth(`/get-payment-details/${id}`),
  delete: async (id) => fetchAPI.getWithAuth(`/delete-payment/${id}`),
  nextNumber: async () => fetchAPI.getWithAuth(`/latest-payment-number`),
  stripeMethod: async (body) =>
    fetchAPI.postWithAuth('/generate-payment-link', body),
  creditCard: async (id, token) =>
    fetchAPI.getAnonymous(`/payment-successful/${id}/${token}`),
  updateBusinessUrl: async (body) =>
    fetchAPI.postWithAuth('/update-connected-account-details', body),
};

export default paymentsAPI;
