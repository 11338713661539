import React from 'react';
import { Container, Row, Col, Alert, Button, Modal } from 'react-bootstrap';

import { Form, Link, useRouteLoaderData } from 'react-router-dom';
import image from '../../img/assets/images/welcome_onboarding.svg';
import IconButton from '../UI/IconButton/IconButton';
import { Carat } from '../UI/Icons/Icons';

function WelcomeView() {
  const {
    user: {
      onboarding_helpers: { new_user: newUser },
    },
  } = (useRouteLoaderData('root') as any) || {}; // *Cries in TypeScript*
  const [showModal, setShowModal] = React.useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  return (
    <>
      <Container className="p-4 pt-5 p-lg-5" fluid>
        <Row className="mb-3">
          <h1 style={{ color: 'var(--bs-primary)' }}>
            Welcome to LeadsBoxInvoice!
          </h1>
        </Row>
        {newUser && (
          <Row className="mb-3">
            <Alert variant="info" style={{ fontSize: '0.9rem' }} dismissible>
              The included PDF features require you to provide some extra
              information on your settings page,{' '}
              <Alert.Link as={Link} to="/settings">
                click here
              </Alert.Link>{' '}
              to complete the required configuration.
            </Alert>
          </Row>
        )}
        <Row className="gx-5 gy-5">
          <Col lg>
            <h2
              className="mb-4"
              style={{ fontWeight: 500, fontSize: 'calc(1.1rem + 0.8vw)' }}
            >
              Start creating invoices right now!
            </h2>
            <p className="mb-3">
              Hello! To begin creating invoices you need to have at least one{' '}
              <strong>customer</strong> and one <strong>product/service</strong>{' '}
              loaded into the app. You can go ahead and explore by yourself or
              complete this quick wizard to create your first invoice.
            </p>
            <p>
              If you choose to, you can also{' '}
              <button
                type="button"
                className="d-inline p-0"
                style={{
                  border: 'none',
                  appearance: 'none',
                  background: 'none',
                  textDecoration: 'underline',
                  textAlign: 'left',
                }}
                onClick={handleOpenModal}
              >
                disable this welcome screen
              </button>{' '}
              to access the dashboard from now on.
            </p>
          </Col>
          <Col className="px-4" lg>
            <img
              style={{ maxHeight: 500, marginLeft: 'auto' }}
              src={image}
              alt="Abstract illustration depicting a person creating invoices"
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="d-flex justify-content-end gap-3">
            <Button variant="secondary" onClick={handleOpenModal}>
              Skip
            </Button>
            <IconButton
              color="primary"
              outline={false}
              text="Start"
              alignText="left"
              style={{ fontWeight: 600 }}
              as={Link}
              to="customer"
            >
              <Carat />
            </IconButton>
          </Col>
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: '0.9rem' }}>
          If you change your mind you can re-enable this tutorial in the
          settings page. It will be only be available as long as you don&apos;t
          have any customers and/or products/services.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Form action="./toggle-tutorial" method="POST">
            <Button type="submit" variant="primary" onClick={handleCloseModal}>
              Confirm
            </Button>
          </Form>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default WelcomeView;
