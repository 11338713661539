/* eslint-disable */

export type Plan = {
  id: string;
  name: string;
  description: string;
  price: {
    monthly: number;
    yearly: number;
  };
  isFeatured: boolean;
  featureList: Array<string>;
  hoverList?: Array<string>;
  maxInvoices: number;
  price_id: string;
};

const planData: Plan[] = [
  {
    id: 'tier_1',
    name: 'Starter',
    description: 'A basic plan to start your Journey.',
    price: {
      monthly: 5.99,
      yearly: 59.99,
    },
    isFeatured: false,
    featureList: [
      '3 invoices/month',
      'Unlimited Estimates',
      'Unlimited Customers',
      'Interactive Dashboard',
      'Expenses',
      'Payments Receipts',
      'Contact Form',
      'Review Form',
      'Customers Details Page',
      'Custom doc. Colors',
      'Payment reminder',
      'Notifications Item',
    ],
    maxInvoices: 3,
    price_id: 'thepriceid',
  },
  {
    id: 'tier_2',
    name: 'Boost',
    description:
      'Grow Faster with Advanced Features designed for your Business. ',
    price: {
      monthly: 9.99,
      yearly: 99.99,
    },
    isFeatured: true,
    hoverList: [
      'Unlimited Estimates',
      'Unlimited Customers',
      'Interactive Dashboard',
      'Expenses',
      'Payments Receipts',
      'Contact Form',
      'Review Form',
      'Customers Details Page',
      'Custom doc. Colors',
      'Payment reminder',
      'Notifications Item',
    ],
    featureList: [
      'Unlimited Invoices',
      'Reports',
      'Profit and Loss Chart',
      'Appointments',
      'Vendors',
      'Profit and Loss per Invoice ',
    ],
    maxInvoices: -1, // To imply "unlimited". I'm open to suggestions for alternatives
    price_id: 'thepriceid',
  },
  {
    id: 'tier_3',
    name: 'Elite',
    description: 'Take your Business even higher.',
    price: {
      monthly: 19.99,
      yearly: 199.99,
    },
    isFeatured: false,
    hoverList: [
      'Unlimited Invoices',
      'Unlimited Estimates',
      'Unlimited Customers',
      'Interactive Dashboard',
      'Expenses',
      'Payment Receipts',
      'Contact Form',
      'Review Form',
      'Customer Details Page',
      'Custom Doc. Colors',
      'Payment Reminder',
      'Notifications Item',
      'Reports',
      'Profit and Loss Chart',
      'Appointments',
      'Vendors',
      'Profit and Loss Per Invoice',
    ],
    featureList: [
      'Recurrent Invoices',
      'Recurrent Expenses',
      'Print Checks',
      'Track 1099 for vendors',
      'Automated Estimate Follow Up',
      'Track Millage',
    ],
    maxInvoices: -1, // To imply "unlimited". I'm open to suggestions for alternatives
    price_id: 'thepriceid',
  },
];

export default planData;
