import React from 'react';
import proptypes from 'prop-types';
import SelectWithDefault from './SelectWithDefault';
import getSelectData from './getSelectData';

const formatInvoiceOptionLabel = ({ number, clientName, balance }) => (
  <span
    style={{
      fontSize: '0.8rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      overflow: 'hidden',
      lineHeight: '100%',
      gap: 6,
    }}
  >
    <span className="text-nowrap">{number}</span>
    <span className="text-truncate text-secondary">{clientName}</span>
    <strong className="text-nowrap" style={{ marginLeft: 'auto' }}>
      {balance}
    </strong>
  </span>
);

function SelectPredefined({
  formType,
  handleChangeSelect,
  inputName,
  isInvalid,
  value,
  hasAll = false,
}) {
  const urls = {
    invoiceSelect: '/invoices',
    productCategoryCombo: '/products/categories',
  };
  const combo = formType === 'productCategoryCombo';
  const options = getSelectData(urls[formType], combo, hasAll);
  const isLoading = !options;

  return (
    <SelectWithDefault
      handleChange={(e) => handleChangeSelect(e)}
      isInvalid={isInvalid}
      name={inputName}
      options={options}
      value={value}
      isLoading={isLoading}
      formatOptionLabel={
        formType === 'invoiceSelect' && formatInvoiceOptionLabel
      }
    />
  );
}

export default SelectPredefined;

SelectPredefined.propTypes = {
  value: proptypes.oneOfType([proptypes.string, proptypes.number]),
  handleChangeSelect: proptypes.func.isRequired,
  isInvalid: proptypes.bool,
  formType: proptypes.string.isRequired,
  inputName: proptypes.string.isRequired,
  hasAll: proptypes.bool,
};

SelectPredefined.defaultProps = {
  value: '',
  isInvalid: false,
};
