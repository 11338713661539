import Payments from '../../components/Payments/Payments';
import PaymentModal from '../../components/Payments/PaymentsModal/PaymentModal';
import paymentsModal from './paymentsModal';
import {
  paymentLoader,
  paymentsLoader,
  stripePayment,
  updateBusinessUrl,
} from './paymentsLA';
import GenericPage from '../../pages/GenericPage';
import paymentCreate from './paymentCreate';
import StripePaymentSuccess from '../../components/Payments/StripePaymentSuccess';

const paymentsRoutes = [
  {
    path: 'payments',
    element: <GenericPage />,
    handle: {
      crumb: () => 'Payments',
    },
    children: [
      {
        path: '',
        element: <Payments />,
        loader: paymentsLoader,
        handle: {
          linkTo: {
            label: 'New',
            to: 'new',
          },
        },

        children: [
          {
            path: ':paymentId',
            element: <PaymentModal />,
            loader: paymentLoader,
            id: 'paymentData',
            children: [...paymentsModal],
          },
        ],
      },
      {
        path: 'update-business-url',
        action: updateBusinessUrl,
      },
      ...paymentCreate,
    ],
  },
  {
    path: 'payment-success/:paymentId/:paymentToken',
    element: <StripePaymentSuccess />,
    loader: stripePayment,
  },
];

export default paymentsRoutes;
