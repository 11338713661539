/* eslint-disable  */
import React, { useEffect } from 'react';
import {
  Outlet,
  useLocation,
  useOutletContext,
  useFetcher,
  useParams,
} from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import SendToButtons from '../UI/buttons/SendToButtons';
import MessageHistory from './MessageHistory';
import IsPdfAllowed from '../PDF/PDFFieldsChecker/IsPdfAllowed';

export interface I_Message {
  email: string;
  from_email: string;
  payment_id?: number;
  payment_number?: string;
  estimate_id?: number;
  estimate_number?: string;
  invoice_id?: number;
  invoice_number?: string;
  message: string;
  date_time: string;
}

function SendTo() {
  const { pathname } = useLocation();
  const { estimateId, invoiceId, paymentId } = useParams();
  const url = pathname.split('/');
  const end = url.pop();
  const { phone, email, subject } = useOutletContext() as {
    phone: string;
    email: string;
    subject: string;
  };
  const fetcher = useFetcher();
  const messageId = estimateId || invoiceId || paymentId;
  const type =
    (estimateId && 'estimate') ||
    (invoiceId && 'invoice') ||
    (paymentId && 'payment');
  const fetcherUrl = `/messages/email/${type}/${messageId}`;

  useEffect(() => {
    if (fetcher.state === 'idle' && !fetcher.data) {
      fetcher.load(fetcherUrl);
    }
  }, [fetcher, messageId]);
  const messagesData = (fetcher?.data?.data?.length > 0 && (
    <MessageHistory messages={fetcher?.data?.data} />
  )) || <Alert variant="secondary">No messages yet</Alert>;
  return (
    <div className="mt-5">
      <IsPdfAllowed template="To be able to send you must">
        <div className="d-flex flex-column side-top-overflow h-md-100 mb-5">
          <div
            className={`d-flex flex-column justify-content-center   ${
              end === 'send' ? '' : 'd-none'
            }`}
          >
            <SendToButtons />
            <section className="mt-5 mb-5 border-0">
              <h3 className="mb-3">History</h3>

              {fetcher.data ? messagesData : 'Loading...'}
            </section>
          </div>

          <Outlet context={{ phone, email, subject }} />
        </div>
      </IsPdfAllowed>
    </div>
  );
}

export default SendTo;
