/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BusinessUrlForm from './BusinessUrlForm';

// eslint-disable-next-line react/prop-types
function StripeConnectAlert({ accountCompleted }) {
  // Initial values

  const renderContent = !accountCompleted ? (
    <>
      To handle credit card payments{' '}
      <Alert.Link as={Link} to="/settings/payment-setup">
        you need to connect stripe to your account.
      </Alert.Link>{' '}
      Please note that you will need to specify a{' '}
      <Alert.Link
        target="_blank"
        referrerPolicy="no-referrer"
        href="https://support.stripe.com/questions/business-website-for-account-activation-faq"
      >
        business URL
      </Alert.Link>{' '}
      on the setup.
    </>
  ) : (
    <div className="d-flex align-items-center justify-content-between">
      <span>
        To handle credit card payments, please add a{' '}
        <Alert.Link
          target="_blank"
          referrerPolicy="no-referrer"
          href="https://support.stripe.com/questions/business-website-for-account-activation-faq"
        >
          business URL
        </Alert.Link>{' '}
        to your stripe account.
      </span>
      <BusinessUrlForm />
    </div>
  );

  return (
    <Alert variant="warning" className="small">
      {renderContent}
    </Alert>
  );
}

export default StripeConnectAlert;
