// Note: Customers Routes
// Import components
import { Outlet } from 'react-router-dom';
import GenericPage from '../../pages/GenericPage';
import Customers from '../../components/Customers/Customers';
import CustomersNew from '../../components/Customers/CustomersNew';
import CustomerViewDetails from '../../components/Customers/CustomerViewDetails';
import ActivityReview from '../../components/Customers/ActivityReview';
import Expenses from '../../components/Expenses/ExpensesOld';
import Payments from '../../components/Payments/Payments';

import PaymentModal from '../../components/Payments/PaymentsModal/PaymentModal';
// Routes

// Loaders and actions
import {
  createCustomerAction,
  deleteCustomerAction,
  exportCsvAction,
  getCustomerActivityLoaderAction,
  getCustomerLoader,
  getCustomersLoader,
  importCsvAction,
  updateCustomerAction,
  updateCustomerNotesAction,
} from './customersLA';
import { paymentLoader, paymentsLoader } from '../payments/paymentsLA';
import { expensesLoader } from '../expenses/expensesLA';
import paymentsModal from '../payments/paymentsModal';
import createInvoice from '../invoices/invoiceCreate';
import createEstimate from '../estimates/estimateCreate';
import paymentCreate from '../payments/paymentCreate';
import createExpense from '../expenses/expenseCreate';
import ErrorMesage from '../../pages/ErrorMesage';
import ImportCsv from '../../components/Customers/CustomersCsv/ImportCsv';
import CsvPage from '../../components/Customers/CustomersCsv/CsvPage';
import ExportCsv from '../../components/Customers/CustomersCsv/ExportCsv';
import ImportResult from '../../components/Customers/CustomersCsv/ImportResult';

const customersRoutes = [
  {
    path: '/customers',
    element: <GenericPage />,
    id: 'customers',
    loader: getCustomersLoader,
    handle: {
      crumb: () => 'Customers',
      sublinks: () => [
        {
          label: 'View all',
          to: '',
          end: true,
        },
        {
          label: 'Import CSV',
          to: 'csv/import/form',
        },
        {
          label: 'Export CSV',
          to: 'csv/export/form',
        },
      ],
    },
    children: [
      {
        path: '',
        element: <Customers />,
        handle: {
          linkTo: {
            label: 'New',
            to: 'new',
          },
        },
      },
      {
        path: 'csv',
        element: <CsvPage />,
        children: [
          {
            path: 'import',
            element: <Outlet />,
            children: [
              {
                path: 'form',
                element: <ImportCsv />,
              },
              {
                path: 'result',
                action: importCsvAction,
                element: <ImportResult />,
              },
            ],
          },
          {
            path: 'export',
            element: <Outlet />,
            children: [
              {
                path: 'form',
                action: exportCsvAction,
                element: <ExportCsv />,
              },
            ],
          },
        ],
      },
      {
        path: 'new',
        element: <CustomersNew />,
        action: createCustomerAction,
        handle: {
          crumb: () => 'New',
        },
      },
      {
        path: ':customerId',
        id: 'getCustomer',
        loader: getCustomerLoader,
        errorElement: <ErrorMesage message="Sorry, get customer failed" />,
        handle: {
          crumb: (match) => match?.data?.data?.name?.fullname || 'All',
          linkTo: {
            label: 'Edit',
            to: '/edit',
            end: true,
          },
          sublinks: (match) => [
            {
              label: 'Details',
              to: `${match.pathname}`,
              end: true,
            },
            {
              label: 'Estimates',
              to: `${match.pathname}/estimates`,
            },
            {
              label: 'Invoices',
              to: `${match.pathname}/invoices`,
            },
            {
              label: 'Payments',
              to: `${match.pathname}/payments`,
            },
            {
              label: 'Expenses',
              to: `${match.pathname}/expenses`,
            },
            {
              label: 'New appointment',
              to: `/appointments/new?customerId=${match.params.customerId}`,
            },
          ],
        },
        children: [
          {
            path: '',
            element: <CustomerViewDetails />,

            children: [
              {
                path: '',
                element: <ActivityReview />,
                loader: getCustomerActivityLoaderAction,
                action: getCustomerActivityLoaderAction,
              },
            ],
          },
          { path: 'updateNotes', action: updateCustomerNotesAction },
          {
            path: 'invoices',
            handle: {
              crumb: () => 'Invoices',
            },
            children: [...createInvoice('customerInvoiceData')],
          },
          {
            path: 'estimates',
            handle: {
              crumb: () => 'Estimates',
            },
            children: [...createEstimate('customerEstimateData')],
          },
          {
            path: 'edit',
            element: <CustomersNew />,
            loader: getCustomerLoader,
            action: updateCustomerAction,
            handle: {
              crumb: () => 'Edit',
            },
          },
          {
            path: 'payments',
            handle: {
              crumb: () => 'Payments',
            },
            children: [
              {
                path: '',
                element: <Payments />,
                loader: paymentsLoader,
                handle: {
                  linkTo: {
                    label: 'New payment',
                    to: 'new',
                  },
                },
                children: [
                  {
                    path: ':paymentId',
                    element: <PaymentModal />,
                    loader: paymentLoader,
                    id: 'customerPaymentData',
                    handle: {
                      crumb: (match) =>
                        `View ${
                          match?.data?.data?.payment?.number || 'Payment'
                        }`,
                    },
                    children: [...paymentsModal],
                  },
                ],
              },
              ...paymentCreate,
            ],
          },

          {
            path: 'expenses',
            handle: {
              crumb: () => 'Expenses',
            },

            children: [
              {
                path: '',
                element: <Expenses />,
                loader: expensesLoader,
                handle: {
                  linkTo: {
                    label: 'New expense',
                    to: 'new',
                  },
                },
              },
              ...createExpense,
            ],
          },

          {
            path: 'delete',
            action: deleteCustomerAction,
          },
        ],
      },
    ],
  },
];
export default customersRoutes;
