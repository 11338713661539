import React from 'react';
import PropTypes from 'prop-types';
import {
  useLoaderData,
  useLocation,
  useParams,
  useRouteLoaderData,
} from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import EstimateNew from './EstimateNew';
import useFetcherOnce from '../Invoices/useFetcherOnce';
import { estimates as paths } from '../../API/routesPaths';

export default function EstimatesNewInitialiazer({ copy = false }) {
  // HOOKS DEFINITION
  const loader = useLoaderData();
  const { estimateId, customerId } = useParams();
  const { data: next } = useFetcherOnce(paths.next, !estimateId || copy);
  const { data: defaultNotes } = useFetcherOnce(paths.messages) || '';
  const customerRoute = customerId && useRouteLoaderData('getCustomer');
  const { state } = useLocation();

  // CONSTANTS DEFINITION
  const TODAY = new Date().toISOString().substring(0, 10);

  // DATA DEFINITION
  const customerLoader = loader?.data?.customer || {};
  const defaultStamentNotes = defaultNotes?.data?.statement_notes || '';
  const documentNumber = next?.estimate_number || '';
  const estimate = loader?.data?.estimate || {};
  /*
     ESTIMATE VARIABLE DATA
    | If the estimateId exists, the estimate is being edited
    | If the copyId exists, the estimate is being copied
    | If none of the above, the estimate is being created from scratch
  */
  estimate.id = estimateId || ''; // check this data
  estimate.items = estimate?.items || [];
  estimate.dates = {
    ...estimate?.dates,
    dateCreated: estimateId ? estimate?.dates?.dateCreated : TODAY,
    dateDue: estimate?.dates?.dateDue || '',
  };
  estimate.notes = {
    ...estimate?.notes,
    statementNotes: estimate?.notes?.statementNotes || defaultStamentNotes,
  };
  estimate.number =
    copy || !estimate?.number ? documentNumber : estimate.number;

  /* 
    CUSTOMER VARIABLE DATA
    | If the customerId exists, the action is bieng done from the customer page
    | If location state is present, we are coming from onboarding (for example)
  */

  let customer = {
    id: '',
    email: '',
    fullAddress: '',
  };

  if (customerLoader?.id) {
    customer = {
      id: customerLoader?.id,
      email: customerLoader?.email,
      fullAddress: customerLoader?.address?.full_address,
    };
  }

  if (customerRoute?.data?.id) {
    customer = {
      id: customerRoute?.data?.id,
      email: customerRoute?.data?.contact?.email,
      fullAddress: customerRoute?.data?.address?.full_address,
    };
  }

  if (state?.customer?.id) {
    customer = {
      id: state?.customer?.id,
      email: state?.customer?.email,
      fullAddress: state?.customer?.fullAddress,
    };
  }

  if (!estimate.number)
    return (
      <div
        style={{
          display: 'grid',
          width: '100%',
          height: '100%',
          placeContent: 'center',
          backgroundColor: 'var(--bs-gray-400)',
          borderRadius: 8,
        }}
      >
        <Spinner />
      </div>
    );

  return <EstimateNew estimate={estimate} customer={customer} copy={copy} />;
}

EstimatesNewInitialiazer.propTypes = {
  copy: PropTypes.bool,
};
