/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSubmit, Outlet } from 'react-router-dom';
import { string } from 'yup';
import useFormikTemplate from '../../Forms/hooks/useFormikTemplate';
import CreateForm from '../../Forms/CreateForm';
import FormWrapper from '../../Forms/builders/FormWrapper';
import ReportDisplay from '../ReportDisplay';
import useFetcherOnce from '../../Invoices/useFetcherOnce';

// // Utils
import getRange from '../utils/getRange';
import getDateFields from '../utils/rangeFields';

// // Constants
import { EXPENSES_TOGGLE } from '../constants/toggleOptions';
import RANGE_OPTIONS from '../constants/rangeOptions';

// // SCSS
import '../Reports.scss';

function Expenses() {
  const submit = useSubmit();
  const expenseCategoriesFetcher = useFetcherOnce('/expenses/categories');
  const merchantsAndVendorsFetcher = useFetcherOnce(
    '/expenses/merchants-and-vendors'
  );
  const [dateRange, setDateRange] = React.useState('customRange');
  const [displayValues, setDisplayValues] = React.useState({});
  const [reportType, setReportType] = React.useState('expenses_by_category');

  const expenseCategories = expenseCategoriesFetcher?.data?.result?.map(
    (category) => ({
      value: category.expense_category_id,
      label: category.expense_category_name,
    })
  ) || [{ value: 'Loading', label: 'Loading' }];

  const vendorOptions = merchantsAndVendorsFetcher?.data?.vendors?.map(
    (vendor) => ({
      value: vendor.id,
      label: vendor.label,
    })
  ) || [{ value: 'Loading', label: 'Loading' }];

  const merchantOptions = merchantsAndVendorsFetcher?.data?.merchants?.map(
    (merchant) => ({
      value: merchant.id,
      label: merchant.label,
    })
  ) || [{ value: 'Loading', label: 'Loading' }];

  const reportTypeField = {
    name: 'report_type',
    label: 'Type',
    initialValue: EXPENSES_TOGGLE[0].value,
    formType: 'toggleGroup',
    options: EXPENSES_TOGGLE,
    type: string().required(),
    required: true,
    className: 'flex__span-12',
    change: (e) => {
      setReportType(e.target.value);
      return null;
    },
  };

  const expenseCategoryField = {
    initialValue: 'all',
    name: 'expense_cat_filter',
    label: 'Filter by Expense Category',
    type: string().required('Please complete the field'),
    formType: 'select',
    options: [{ value: 'all', label: 'All' }, ...expenseCategories],
    required: true,
    isClearable: false,
    className: 'flex__span-12',
  };

  const invoiceFilterField = {
    name: 'invoice_filter',
    label: 'Invoice',
    formType: 'invoiceSelect',
    required: true,
    initialValue: 'all',
    type: string().required('Please complete the field'),
    hasAll: true,
    className: 'flex__span-12',
  };

  const vendorFilterField = {
    name: 'vendor_filter',
    label: 'Vendor',
    formType: 'select',
    required: true,
    initialValue: 'all',
    type: string().required('Please complete the field'),
    options: [{ value: 'all', label: 'All' }, ...vendorOptions],
    isClearable: false,
    className: 'flex__span-12',
  };

  const merchantFilterField = {
    name: 'merchant_filter',
    label: 'Merchant',
    formType: 'select',
    required: true,
    initialValue: 'all',
    type: string().required('Please complete the field'),
    options: [{ value: 'all', label: 'All' }, ...merchantOptions],
    isClearable: false,
    className: 'flex__span-12',
  };

  const rangeFields = [
    {
      name: 'select_range',
      label: 'Range',
      formType: 'select',
      initialValue: RANGE_OPTIONS[0].value,
      options: RANGE_OPTIONS,
      type: string(),
      isClearable: false,
      className: 'flex__span-3',
      change: async (e) => {
        setDateRange(e);
        if (e === 'customRange') return null;
        const range = getRange(e);
        await formik.setFieldValue('date_from', range.startDate, true);
        await formik.setFieldValue('date_to', range.endDate, true);
        return null;
      },
    },
    ...getDateFields({ dateRange, reportType }),
  ];

  const submitAction = (values) => {
    setDisplayValues((prev) => ({
      ...prev,
      dateTo: values.date_to,
      dateFrom: values.date_from,
      reportType: values.report_type,
    }));

    submit(
      {
        to: values.date_to,
        from: values.date_from,
        expenseCategory: values.expense_cat_filter,
        invoice: values.invoice_filter,
        merchant: values.merchant_filter,
        vendor: values.vendor_filter,
      },
      {
        method: 'POST',
        action: values.report_type.replaceAll('_', '-'),
        encType: 'application/json',
      }
    );
  };

  const [formik] = useFormikTemplate({
    initial: [
      ...rangeFields,
      reportTypeField,
      expenseCategoryField,
      invoiceFilterField,
      vendorFilterField,
      merchantFilterField,
    ],
    yupValues: [
      ...rangeFields,
      reportTypeField,
      expenseCategoryField,
      invoiceFilterField,
      vendorFilterField,
      merchantFilterField,
    ],
    submitAction,
    enableReinitialize: false,
  });

  return (
    <div className="reports__wrapper">
      <div className="reports__wrapper__sticky-container">
        <div className="reports__wrapper__action-wrapper  rounded shadow-sm p-4">
          <FormWrapper
            id="expensesForm"
            label="Run Report"
            handleSubmit={formik.handleSubmit}
            isSubmitting={formik.isSubmitting}
            dirty={formik.dirty}
            isValid={formik.isValid}
            portalId="report-submit"
            className="flex__form-wrapper"
          >
            <div className="form-two">
              <CreateForm structure={[reportTypeField]} {...formik} />

              {reportType === 'expenses_by_category' && (
                <CreateForm structure={[expenseCategoryField]} {...formik} />
              )}

              {reportType === 'expenses_by_invoice' && (
                <CreateForm structure={[invoiceFilterField]} {...formik} />
              )}

              {reportType === 'expenses_by_vendor' && (
                <CreateForm structure={[vendorFilterField]} {...formik} />
              )}

              {reportType === 'expenses_by_merchant' && (
                <CreateForm structure={[merchantFilterField]} {...formik} />
              )}

              <CreateForm structure={rangeFields} {...formik} />

              <div
                id="report-submit"
                className="flex__span-3"
                style={{ paddingTop: '1.25rem' }}
              />
            </div>
          </FormWrapper>
        </div>
      </div>
      <ReportDisplay reportValue={displayValues.reportType}>
        <Outlet
          context={{
            reportValue: displayValues.reportType,
            dateTo: displayValues.dateTo,
            dateFrom: displayValues.dateFrom,
          }}
        />
      </ReportDisplay>
    </div>
  );
}

export default Expenses;
