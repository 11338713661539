import React from 'react';
import { useLoaderData } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
function ConnectStripeBtn({ accountCompleted }) {
  const { url } = useLoaderData();
  return (
    <a
      href={url}
      className="d-flex align-self-start gap-2 align-items-center btn btn-outline-indigo"
    >
      {accountCompleted ? 'Go to' : 'Connect with'}{' '}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 198.27 88.67"
        className="stripe-logo"
      >
        <path d="M195.26,45.67c0-13.67-6.62-24.46-19.28-24.46s-20.4,10.79-20.4,24.35c0,16.07,9.08,24.19,22.11,24.19,6.36,0,11.16-1.44,14.79-3.47v-10.68c-3.63,1.82-7.8,2.94-13.08,2.94s-9.77-1.82-10.36-8.12h26.11c0-.69.11-3.47.11-4.75ZM168.88,40.6c0-6.03,3.68-8.54,7.05-8.54s6.73,2.51,6.73,8.54h-13.78ZM134.97,21.21c-5.23,0-8.6,2.46-10.47,4.17l-.69-3.31h-11.75v62.27l13.35-2.83.05-15.11c1.92,1.39,4.75,3.36,9.45,3.36,9.56,0,18.26-7.69,18.26-24.62-.05-15.49-8.87-23.93-18.21-23.93h0ZM131.76,58c-3.15,0-5.02-1.12-6.3-2.51l-.05-19.81c1.39-1.55,3.31-2.62,6.36-2.62,4.86,0,8.22,5.45,8.22,12.44s-3.31,12.5-8.22,12.5ZM93.69,18.06l13.4-2.88V4.33l-13.4,2.83v10.89ZM93.69,22.12h13.4v46.73h-13.4V22.12ZM79.32,26.07l-.85-3.95h-11.54v46.73h13.35v-31.67c3.15-4.11,8.49-3.36,10.15-2.78v-12.28c-1.71-.64-7.96-1.82-11.11,3.95h0ZM52.62,10.53l-13.03,2.78-.05,42.78c0,7.9,5.93,13.72,13.83,13.72,4.38,0,7.58-.8,9.35-1.76v-10.84c-1.71.69-10.15,3.15-10.15-4.75v-18.96h10.15v-11.38h-10.15s.05-11.59.05-11.59ZM16.52,35.68c0-2.08,1.71-2.88,4.54-2.88,4.06,0,9.19,1.23,13.24,3.42v-12.55c-4.43-1.76-8.81-2.46-13.24-2.46-10.84,0-18.05,5.66-18.05,15.11,0,14.74,20.29,12.39,20.29,18.74,0,2.46-2.14,3.26-5.13,3.26-4.43,0-10.09-1.82-14.58-4.27v12.71c4.97,2.14,9.99,3.04,14.58,3.04,11.11,0,18.74-5.5,18.74-15.06-.05-15.91-20.4-13.08-20.4-19.07h0Z" />
      </svg>
    </a>
  );
}

export default ConnectStripeBtn;
