import React from 'react';
import './PricingToggle.scss';

export type PricingOptions = 'monthly' | 'yearly';

interface PricingToggleProps {
  pricing: PricingOptions;
  togglePricing: (opt: PricingOptions) => void;
}

function PricingToggle({ pricing, togglePricing }: PricingToggleProps) {
  return (
    <div className="pricing-toggle__wrapper">
      <span className="pricing-toggle__notice">
        Pay for annual subscription and get 2 months free
      </span>
      <div className="pricing-toggle__toggle-wrapper">
        <button
          type="button"
          className={`btn ${pricing === 'monthly' ? 'btn-primary' : 'btn-tertiary'}`}
          onClick={() => togglePricing('monthly')}
        >
          Monthly
        </button>
        <button
          type="button"
          className={`btn ${pricing === 'yearly' ? 'btn-primary' : 'btn-tertiary'}`}
          onClick={() => togglePricing('yearly')}
        >
          Yearly
        </button>
      </div>
    </div>
  );
}

export default PricingToggle;
